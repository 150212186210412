import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import getName from "../util/getName";
import { AppContext } from "../util/AppContext";
import { ProductsModal, ProductsModalButton } from "../components/ProductsModal";
import MessageTypes from "../util/messageTypes";
import { getConfigValue } from "../util/getConfigValue";
import AlertModal from '../components/AlertModal';
import { getBatchPrintMessages } from "../API/batchPrint";
import { useErrorBoundary } from "react-error-boundary";
import { hcpType } from "../util/constants";

function MessageSelectionPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [productMessages, setProductMessages] = useState([]);
    const [currentProduct, setCurrentProduct] = useState();
    const [productImageSource, setProductImageSource] = useState();
    const { state } = useLocation();
    const [alert, setAlert] = useState(state?.alert || null);
    const prescriberType = (appState?.prescriberType);
    const focusType = (appState?.focus);
    const [batchPrintMessages, setBatchPrintMessages] = useState([]);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (appState?.currentProduct === "") {
            for (let index = 0; index < appState?.productDetails.length; index++) {
                const product = appState?.products?.find(p => p.productId === appState?.productDetails[index].clientProductId);
                if (product && product?.messages?.length > 0) {
                    setCurrentProduct(appState?.productDetails[index]);
                    setProductMessages(product.messages);
                    setProductImageSource(appState?.productDetails[index].imagePath);
                    setAppState({ ...appState, currentProduct: appState?.productDetails[index] });
                    break;
                }
            }
        } else {
            setCurrentProduct(appState.currentProduct);
            setProductMessages(appState?.products?.find(i => i.productId === appState.currentProduct?.clientProductId)?.messages)
            setProductImageSource(appState.currentProduct?.imagePath);
        }
    }, [appState, appState.currentProduct, appState?.productDetails, setAppState])

    useEffect(() => {
        if ((focusType === "HCPs" && (!productMessages || productMessages.length === 0)) || (focusType === "Messages" && (!batchPrintMessages || batchPrintMessages.length === 0))) {
            setAlert({ type: 'primary', heading: (getConfigValue(appState?.config?.MessageSelectionPage?.InfoMessage?.Title, appState)?.Value || null), message: (getConfigValue(appState?.config?.MessageSelectionPage?.InfoMessage?.Body, appState)?.Value || null) });
        } else {
            setAlert()
        }
    }, [appState, productMessages, batchPrintMessages])

    useEffect(() => {
        const getDefaultProductBatchPrint = async () => {
            for (let index = 0; index < appState?.productDetails.length; index++) {
                const product = appState?.productDetails[index].clientProductId;
                if (product) {
                    setCurrentProduct(appState?.productDetails[index]);
                    setProductImageSource(appState?.productDetails[index].imagePath);
                    const result = await getBatchPrintMessages(appState?.territory.id, appState?.productDetails[index].clientProductId);
                    const categories = result[0].messageTypes.map(m => ({category: m.name, messageCode: m.messageCode, messageTypeId: m.messageTypeId, messageType: m.messageType}));
                    setAppState({ ...appState, currentProduct: appState?.productDetails[index], batchPrintCategories: categories});
                    setBatchPrintMessages(categories);
                    break;
                }
            }
        }

        const getCurrentProductBatchPrint = async () => {
            setCurrentProduct(appState.currentProduct);
            setProductImageSource(appState.currentProduct?.imagePath);
            const result = await getBatchPrintMessages(appState?.territory.id, appState?.currentProduct.clientProductId);
            const categories = result[0].messageTypes.map(m => ({category: m.name, messageCode: m.messageCode, messageTypeId: m.messageTypeId, messageType: m.messageType}));
            setAppState({ ...appState, batchPrintCategories: categories});
            setBatchPrintMessages(categories);
        };
        if (focusType === "Messages") {
            if (appState?.currentProduct === "") {
                getDefaultProductBatchPrint().catch(error => { showBoundary(error) });
            } else {
                getCurrentProductBatchPrint().catch(error => { showBoundary(error) });
            }
        }
    }, [appState.territory.id, appState.currentProduct, appState?.productDetails, setAppState])

    function handleCategoryButtonClick(category) {
        setAppState({ ...appState, category: category });
    }

    function onProductClick(product) {
        setAppState({ ...appState, currentProduct: product });
        setCurrentProduct(product);
        setShowProductsModal(false);
        setProductMessages(appState?.products?.find(i => i.productId === product.clientProductId)?.messages)
        setProductImageSource(product.imagePath)
    }

    return (
        <div>
            <div className="title-bar">
                <div className="container-xl">
                    <div className="row">
                        {focusType === "HCPs" &&
                            <div className="col-12">
                                <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                                <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                    <li className="wizard-step state-complete">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-complete">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-current">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-future">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                        {focusType === "Messages" &&
                            <div className="col-12">
                                <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                                <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                    <li className="wizard-step state-complete">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-current">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-future">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                    </li>
                                    <li className="wizard-step state-future">
                                        <span className="wizard-step-icon"></span>
                                        <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="container-xl mt-3 mt-md-4">
                <div className="row d-flex align-items-md-center mb-md-5">
                    <div className="col-12 col-md-6 order-2 order-md-1">
                        <h1 className="type-20 color-grey-d1 mt-4 mt-md-0">
                            {getConfigValue(appState.config?.MessageSelectionPage?.CallToAction, appState)?.Value}
                        </h1>
                    </div>
                    <div className="col-12 col-md-6 order-1 order-md-2">
                        {focusType === "HCPs" ?
                            <div className="row justify-content-end">
                                <div className="col-6 col-md-auto type-14 text-md-end">
                                    <div className="color-grey-d1">
                                        {prescriberType === hcpType.Prescriber ? getConfigValue(appState.config?.MessageSelectionPage?.ProviderLabel, appState)?.Value : getConfigValue(appState.config?.MessageSelectionPage?.GroupPracticeLabel, appState)?.Value}
                                    </div>
                                    {getName(appState)}
                                </div>
                                <div className="col-6 col-md-auto type-14 text-md-end">
                                    <div className="color-grey-d1">
                                        {getConfigValue(appState.config?.MessageSelectionPage?.TerritoryLabel, appState)?.Value}
                                    </div>
                                    {appState.territory?.name}
                                </div>
                            </div>
                            : ""}
                    </div>
                </div>

                <div className="row">
                    {/* This section will be replaced with the config data to pull in the brands */}
                    <div className="col-12 col-md-4 col-lg-3">
                        <ProductsModalButton
                            setShowProductsModal={setShowProductsModal}
                            productImageSource={productImageSource}
                            productName={currentProduct?.name}
                        ></ProductsModalButton>
                    </div>
                    {alert && (
                        <div className="col-12 col-md-8 col-lg-9">
                            <AlertModal alert={alert} />
                        </div>
                    )}
                    {/* This section will be replaced with the config data to pull in the brands */}
                    <div className="col-12 col-md-8 col-lg-9">
                        {focusType === "HCPs" && productMessages?.length > 0 &&
                            productMessages.toSorted((a, b) => a.categoryDisplayOrder - b.categoryDisplayOrder).map((message, index) => {
                                return (
                                    <React.Fragment key={message.messageCode}>
                                        {index > 0 && (
                                            <hr className="my-5" />
                                        )}

                                        <div className="row">
                                            <div className="col-12 col-lg-8">
                                                <h2 className="type-20 fw-bold">
                                                    {message.category}
                                                </h2>
                                                <div
                                                    className="my-md-0"
                                                    dangerouslySetInnerHTML={{
                                                        __html: message.message,
                                                    }}>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 d-lg-flex flex-column justify-content-lg-center">
                                                {/* Link them to Improved access */}
                                                <Link
                                                    to={`${message.messageType === MessageTypes.ImprovedAccess ? '/WinPlanListPage' : '/PlanListPage'}`}
                                                    data-messagecode={message.messageCode}
                                                    onClick={() => handleCategoryButtonClick(message)}
                                                    className="d-inline-flex btn btn-outline-primary btn-icon mt-2"
                                                >
                                                    {message.category}{" "}
                                                    <span className="fa-regular fa-chevron-right btn-icon"></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        {focusType === "Messages" && batchPrintMessages?.length > 0 &&
                            batchPrintMessages?.toSorted((a, b) => a.categoryDisplayOrder - b.categoryDisplayOrder).map((message, index) => {
                                return (
                                    <React.Fragment key={message.messageCode}>
                                        {index > 0 && (
                                            <hr className="my-5" />
                                        )}
                                        <div className="row">
                                            <div className="col-12 col-lg-8">
                                                <h2 className="type-20 fw-bold">
                                                    {message.category}
                                                </h2>
                                            </div>
                                            <div className="col-12 col-lg-4 d-lg-flex flex-column justify-content-lg-center">
                                                {/* Link them to Improved access */}
                                                <Link
                                                    to={'/SelectTargetBatchPage'}
                                                    data-messagecode={message.messageCode}
                                                    onClick={() => handleCategoryButtonClick(message)}
                                                    className="d-inline-flex btn btn-outline-primary btn-icon mt-2"
                                                >
                                                    {message.category}{" "}
                                                    <span className="fa-regular fa-chevron-right btn-icon"></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })
                        }

                        <div className="my-7"></div>
                    </div>
                    <div className="container-xl">
                        <div className="row justify-content-center justify-content-md-end py-3">
                            <div className="col-12 col-md-8 col-lg-9">
                                <hr className="my-4" />
                            </div>
                        </div>
                        <div className="row justify-content-center justify-content-md-end mb-5">
                            <div className="col-10 col-sm-8 col-md-4 col-lg-3">
                                {focusType === "HCPs" &&
                                    <Link
                                        to="/HCPPage"
                                        className="btn btn-secondary btn-lg flex-fill w-100">
                                        {getConfigValue(appState.config?.Common?.Back, appState)?.Value || null}
                                    </Link>
                                }
                                {focusType === "Messages" &&
                                    <Link
                                        to="/TerritoryPage"
                                        className="btn btn-secondary btn-lg flex-fill w-100">
                                        {getConfigValue(appState.config?.Common?.Back, appState)?.Value || null}
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductsModal
                show={showProductsModal}
                setShow={setShowProductsModal}
                onProductClick={onProductClick}
            />
        </div>
    );
}

export default MessageSelectionPage;
