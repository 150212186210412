import React from "react";
import PropTypes from 'prop-types';

DashboardRowBatchItem.propTypes = {
    hcp: PropTypes.any,
};

function DashboardRowBatchItem({ hcp }) {

    return (
        <div className="row align-items-lg-center mx-0 py-2 border-bottom">
            <div className="pd-plan-col col-sm col-lg-4 offset-lg-1">
                <div className="row align-items-lg-center">
                    <div className="col-12">
                        <span className="pd-plan-table-label d-block d-lg-none"></span>{hcp.providerName}
                    </div>
                </div>
            </div>
            <div className="pd-plan-col col-sm col-lg-5">
                <div className="row align-items-lg-center">
                    <div className="col-sm col-lg">
                        <span className="pd-plan-table-label d-block d-lg-none"></span>{hcp.zipCode}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardRowBatchItem;