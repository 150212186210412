import { useState, useEffect, useRef, useContext } from 'react';
import { useLocation } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import useComponentVisible from '../hooks/useComponentVisible';
import Tooltip from './Tooltip';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';
import Mustache from 'mustache';
import { getSanitizedHtml } from '../util/getSanitizedHtml';
import { computeBorderColorClass, getIndicatorClass } from '../util/getBorderColor'
import RestrictionModal from './modals/RestrictionModal';
import FormularyModal from './modals/FormularyModal';
import SpecialtyPharmacyModal from './modals/SpecialtyPharmacyModal'
import PropType from 'prop-types';
import PrintRestrictionModal from './modals/PrintRestrictionModal';

PlanListRow.propTypes = {
	plan: PropType.shape({
		data: PropType.shape({
			name: PropType.string,
			benType: PropType.string,
			isPrintable: PropType.bool,
			planClass: PropType.string,
			isWin: PropType.bool,
			formularyRestriction: PropType.string,
			planId: PropType.string,
			formularyDrillDown: PropType.arrayOf(
				PropType.shape({
					formulary: PropType.string,
					coverage: PropType.string,
					livesPercentage: PropType.number,
				})
			),
			specialtyPharmacies: PropType.array,
			minPrintPlans: PropType.number
		}),
	}),
	toggleCollapse: PropType.bool,
	exceedMaxColumns: PropType.bool,
	tableColumnHeaders: PropType.array,
	selectedPlans: PropType.array,
	isSingleSelection: PropType.bool,
	index: PropType.number,
	isExpandAll: PropType.bool,
	isImprovedAccessStatic: PropType.bool,
	onOptionsClick: PropType.func,
	onClick: PropType.func,
	formularyDrillDownEnabled: PropType.bool,
	restrictionDrillDownEnabled: PropType.bool,
	specialtyPharmacyDrillDownEnabled: PropType.bool,
	canSelectFormulary: PropType.bool,
	canSelectRestrictions: PropType.bool,
	canSelectSpecialityPharmacy: PropType.bool
};

function PlanListRow({
	plan,
	onClick,
	onOptionsClick,
	isImprovedAccessStatic,
	isExpandAll,
	index,
	isSingleSelection,
	selectedPlans,
	tableColumnHeaders,
	exceedMaxColumns,
	toggleCollapse,
	formularyDrillDownEnabled,
	restrictionDrillDownEnabled,
	specialtyPharmacyDrillDownEnabled,
	canSelectFormulary,
	canSelectRestrictions,
	canSelectSpecialityPharmacy
}) {
	const [appState] = useContext(AppContext);
	const [optionsMenuRef, optionsMenuIsVisible, setOptionsMenuIsVisible] = useComponentVisible(false);
	const { state } = useLocation();

	const [moreInfoMenuIsVisible, setMoreInfoMenuIsVisible] = useState(false);
	const [includeRestrictions, setIncludeRestrictions] = useState(false);
	const [includeFormularies, setIncludeFormularies] = useState(false);
	const [includeSpecialty, setIncludeSpecialty] = useState(false);
	const [improvedAccessSelectedPlan, setImprovedAccessSelectedPlan] = useState(false);
	const isCurrentPlanSelected = selectedPlans.filter((e) => e.planId === plan.data.planId).length > 0;
	const showWarning =
		(plan.data.minPrintPlans &&
			parseInt(plan.data.minPrintPlans) > 0 &&
			isCurrentPlanSelected &&
			selectedPlans.filter((e) => e.planClass !== plan.data.planClass).length < parseInt(plan.data.minPrintPlans)) ||
		false;

	let warningLabel = getConfigValue(appState.config.Plan.SelectionMessage, appState)?.Value;
	warningLabel = Mustache.render(warningLabel, plan.data);

	const [warningVisible, setWarningVisible] = useState(false);

	const [showTooltip, setShowTooltip] = useState(false);
	const tooltipTarget = useRef(null);
	const [maxDisplayColumns, setMaxDisplayColumns] = useState(5);

	const [showRestrictionModal, setShowRestrictionModal] = useState(false);
	const [showFormularyModal, setShowFormularyModal] = useState(false);
	const [showSpecialtyPharmacy, setShowSpecialtyPharmacy] = useState(false);
	const [showPrintRestrictionModal, setShowPrintRestrictionModal] = useState(false);

	const { name, benType, isPrintable, planClass, isWin, formularyRestriction, formularyDrillDown, specialtyPharmacies } = plan.data;
	const showEllipsisButton = (formularyDrillDownEnabled && formularyDrillDown && formularyDrillDown.length > 0) || (restrictionDrillDownEnabled && formularyRestriction && formularyRestriction.length > 0) || (specialtyPharmacyDrillDownEnabled && specialtyPharmacies && specialtyPharmacies.length > 0);

	useEffect(() => {
		setMoreInfoMenuIsVisible(isExpandAll);
	}, [isExpandAll, toggleCollapse]);

	useEffect(() => {
		if (appState?.config?.PlanList?.MaxDisplayColumns) {
			setMaxDisplayColumns(parseInt(appState.config.PlanList.MaxDisplayColumns.Value));
		}
	}, [appState]);

	useEffect(() => {
		const selected = selectedPlans.find((e) => e.planId === plan.data.planId);
		if (!selected) {
			setIncludeFormularies(false);
			setIncludeRestrictions(false)
			setIncludeSpecialty(false);
		}
	}, [selectedPlans, plan.data.planId]);

	let isWarningRow = planClass !== null;
	let isDangerRow = !isPrintable;

	let borderColorClass = computeBorderColorClass(isWin, isPrintable, planClass);

	useEffect(() => {
		if (state?.planId) {
			setImprovedAccessSelectedPlan(plan.data.planId === state?.planId);
		} else {
			setImprovedAccessSelectedPlan(false);
		}
	}, [state?.planId, plan.data.planId])

	function canShowWarning() {
		if (isSingleSelection) {
			return warningVisible;
		}

		return showWarning;
	}

	function singleSelect(plan, event) {
		if (!isWarningRow) {
			onClick(plan.data, event);
			return null;
		} else {
			setWarningVisible(!warningVisible);
		}
	}

	function handleIncludeRestrictionsClick(event) {
		//only set the state if the onOptionsClick function returns true
		if (onOptionsClick({ ...plan.data, includeRestrictions: event.target.checked, includeFormularies: includeFormularies, includeSpecialtyPharmacy: includeSpecialty })) {
			setIncludeRestrictions(event.target.checked);
		} else {
			event.preventDefault();
		}
	}

	function handleIncludeFormulariesClick(event) {
		//only set the state if the onOptionsClick function returns true
		if (onOptionsClick({ ...plan.data, includeFormularies: event.target.checked, includeRestrictions: includeRestrictions, includeSpecialtyPharmacy: includeSpecialty })) {
			setIncludeFormularies(event.target.checked);
		} else {
			event.preventDefault();
		}
	}

	function handleIncludeSpecialtyClick(event) {
		//only set the state if the onOptionClick returns true
		if (onOptionsClick({ ...plan.data, includeSpecialtyPharmacy: event.target.checked, includeFormularies: includeFormularies, includeRestrictions: includeRestrictions })) {
			setIncludeSpecialty(event.target.checked);
		} else {
			event.preventDefault();
		}
	}

	return (
		<div
			className={`row pd-plan-table-row ${borderColorClass ? getIndicatorClass(borderColorClass) : ''
				} ${improvedAccessSelectedPlan ? "color-bg-warning-15" : ""} pd-plan-table-card mb-6 mb-lg-0 pt-3 pt-lg-0 justify-content-lg-between align-items-lg-center`}>
			<div className='pd-plan-col col-12 col-lg-1 px-0 py-lg-3'>
				<div className='pd-plan-table-col pd-plan-table-input-main'>
					{isDangerRow ? (
						<>
							<span
								ref={tooltipTarget}
								onMouseOver={() => setShowTooltip(true)}
								onFocus={() => setShowTooltip(true)}
								onMouseOut={() => setShowTooltip(false)}
								onBlur={() => setShowTooltip(false)}
								className='far fa-print-slash type-20 color-danger'
								id={`${benType}${name}`}></span>
							<Tooltip
								text={`${getConfigValue(appState.config?.PlanList?.PrintRestrictionTooltip, appState)?.Value}`}
								ref={tooltipTarget}
								showTooltip={showTooltip}
								index={index}
							/>
						</>
					) : (
						<div>
							{planClass && isSingleSelection ?
								<button className="far fa-print-slash type-20 color-grey-dark-1 btn-reset"
									onClick={() => setShowPrintRestrictionModal(true)}>
								</button>
								:
								//   <!-- DEV NOTES Need to alternate between different types of checkboxes and radio button. If they are in static Improved Access use radio button else checkbox && show only winPlans --> */
								<div className='form-check'>
									{isSingleSelection ? (
										// if from static improved access or is single selection, use radio button:
										<input
											className='form-check-input'
											onChange={(event) => singleSelect(plan, event)}
											type='radio'
											name='planlist-radio-group'
											checked={isCurrentPlanSelected}
											id={`planlist-input-check-row${index}`}
											data-plan-alert={`planlist-plan-alert-row${index}`}
										/>
									) : (
										// else use checkbox:
										<input
											className='form-check-input'
											onChange={(event) => onClick(plan.data, event)}
											type='checkbox'
											checked={improvedAccessSelectedPlan || isCurrentPlanSelected}
											id={`planlist-input-check-row${index}`}
											data-plan-alert={`planlist-plan-alert-row${index}`}
										/>
									)}

									{/* <!-- DEV NOTES Note the text strings of Channel + Plan in the <label> element --> */}
									<label className='form-check-label visually-hidden' htmlFor={`planlist-input-check-row${index}`}>
										Select {benType} - {name}
									</label>
								</div>
							}
						</div>
					)}
				</div>
			</div>

			{/* <!-- Plan alert --> */}

			<div
				className='pd-plan-col pd-plan-alert col-12 order-lg-last collapse'
				style={{ display: canShowWarning() ? 'flex' : 'none' }}
				id={`planlist-plan-alert-row${index}`}>
				<span className='fas fa-info-circle'></span>
				<p className='my-0 ms-3'>{warningLabel}</p>
			</div>

			<div className='pd-plan-col pd-plan-variable col-12 col-lg-9'>
				<div className='row align-items-lg-center'>
					{Object.entries(plan.columns).map(([key, value], index) => {
						return (
							index < maxDisplayColumns && (
								<div key={key} className='col-6 col-lg mb-3 my-lg-0'>
									<div className='pd-plan-table-value' dangerouslySetInnerHTML={getSanitizedHtml(value)}></div>
								</div>
							)
						);
					})}
				</div>
			</div>

			<div ref={optionsMenuRef} className='pd-plan-col pd-plan-button pd-plan-button-options col-12 col-lg-1 mt-4 mb-2 my-lg-0'>
				{/* <!-- Options buttons and check boxes --> */}

				<div className='pd-plan-col pd-plan-options col-12 mb-3' id='options-row-1' style={{ display: optionsMenuIsVisible ? 'block' : 'none' }}>
					<div className='row justify-content-center align-items-center'>
						<div className='col-10 py-3'>
							{formularyDrillDownEnabled && formularyDrillDown && formularyDrillDown.length > 0 && (
								<>
									<h3 className='type-16 type-bold'>{getConfigValue(appState.config?.PlanList?.Options?.Formularies, appState)?.Value}</h3>
									<div className='d-flex justify-content-between align-items-center mb-5'>
										<button
											className='btn btn-outline-primary btn-icon btn-sm d-flex align-items-center'
											onClick={() => setShowFormularyModal(true)}>
											{getConfigValue(appState.config?.PlanList?.Options?.ShowList?.Label, appState)?.Value}{' '}
											<span className='fa-regular fa-chevron-right btn-icon'></span>
										</button>
										<div>
											<div className={`form-check ${canSelectFormulary ? '' : 'disabled'}`}>
												<input
													className='form-check-input'
													type='checkbox'
													checked={includeFormularies}
													onChange={handleIncludeFormulariesClick}
													id={`planlist-input-options-formularies-row${index}`}
													data-plan-alert={`planlist-plan-alert-row${index}`}
												/>
												<label className='form-check-label type-14' htmlFor={`planlist-input-options-formularies-row${index}`}>
													{getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.Label, appState)?.Value}
												</label>
											</div>
										</div>
									</div>
								</>
							)}
							{restrictionDrillDownEnabled && formularyRestriction && formularyRestriction.length > 0 && (
								<>
									<h3 className='type-16 type-bold'>{getConfigValue(appState.config?.PlanList?.Options?.Restrictions, appState)?.Value}</h3>
									<div className='d-flex justify-content-between align-items-center mb-5'>
										<button
											className='btn btn-outline-primary btn-icon btn-sm d-flex align-items-center'
											onClick={() => setShowRestrictionModal(true)}>
											{getConfigValue(appState.config?.PlanList?.Options?.ShowList?.Label, appState)?.Value}{' '}
											<span className='fa-regular fa-chevron-right btn-icon'></span>
										</button>
										<div>
											<div className={`form-check ${canSelectRestrictions ? '' : 'disabled'}`}>
												<input
													className='form-check-input'
													type='checkbox'
													checked={includeRestrictions}
													onChange={handleIncludeRestrictionsClick}
													id={`planlist-input-options-restrictions-row${index}`}
													data-plan-alert={`planlist-plan-alert-row${index}`}
												/>
												<label className='form-check-label type-14' htmlFor={`planlist-input-options-restrictions-row${index}`}>
													{getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.Label, appState)?.Value}
												</label>
											</div>
										</div>
									</div>
								</>
							)}
							{specialtyPharmacyDrillDownEnabled && specialtyPharmacies && specialtyPharmacies.length > 0 && (
								<>
									<h3 className='type-16 type-bold'>{getConfigValue(appState.config?.PlanList?.Options?.SpecialtyPharmacy, appState)?.Value}</h3>
									<div className='d-flex justify-content-between align-items-center mb-5'>
										<button
											className='btn btn-outline-primary btn-icon btn-sm d-flex align-items-center'
											onClick={() => setShowSpecialtyPharmacy(true)}>
											{getConfigValue(appState.config?.PlanList?.Options?.ShowList?.Label, appState)?.Value}{' '}
											<span className='fa-regular fa-chevron-right btn-icon'></span>
										</button>
										<div>
											<div className={`form-check ${canSelectSpecialityPharmacy ? '' : 'disabled'}`}>
												<input
													className='form-check-input'
													type='checkbox'
													checked={includeSpecialty}
													onChange={handleIncludeSpecialtyClick}
													id={`planlist-input-options-specialtypharm-row${index}`}
													data-plan-alert={`planlist-plan-alert-row${index}`}
												/>
												<label className='form-check-label type-14' htmlFor={`planlist-input-options-specialtypharm-row${index}`}>
													{getConfigValue(appState.config?.PlanList?.Options?.IncludeInPrint?.Label, appState)?.Value}
												</label>
											</div>
										</div>
									</div>
								</>
							)}

						</div>
					</div>
				</div>

				{/* <!-- Button - options --> */}

				<div className='row justify-content-center'>
					<div className='col-11 col-sm-8'>
						{!isDangerRow && showEllipsisButton && (
							<>
								<div className='d-flex flex-column'>
									<button
										className='btn btn-outline-primary btn-md btn-icon d-lg-none'
										onClick={() => setOptionsMenuIsVisible(!optionsMenuIsVisible)}
										data-bs-toggle='collapse'
										data-bs-target={`#options-row-${index}`}
										aria-expanded={optionsMenuIsVisible}
										aria-controls={`options-row-${index}`}>
										{getConfigValue(appState.config?.PlanList?.Options?.Label, appState)?.Value}{' '}
										<span className='fa-regular fa-ellipsis btn-icon'></span>
									</button>
								</div>
								<button
									className='btn btn-ghost-secondary btn-sm btn-icon-only d-none d-lg-flex'
									onClick={() => setOptionsMenuIsVisible(!optionsMenuIsVisible)}
									data-bs-toggle='collapse'
									data-bs-target={`#options-row-${index}`}
									aria-expanded={optionsMenuIsVisible}
									aria-controls={`options-row-${index}`}>
									<span className='fa-regular fa-ellipsis btn-icon'></span>
								</button>
							</>
						)}
					</div>
				</div>
			</div>
			{/* <!-- Data table --> */}
			{exceedMaxColumns && (
				<Collapse in={moreInfoMenuIsVisible}>
					<div className='pd-plan-col pd-plan-datatable col-12 order-lg-last px-0' id={`datatable-row-${index}`}>
						<div className='pd-plan-datatable'>
							<div className='pd-plan-datatable-row'>
								<div className='pd-plan-datatable-col pd-datatable-label'>
									{tableColumnHeaders.map((header) => {
										return <div key={header.key} className='col-lg' dangerouslySetInnerHTML={getSanitizedHtml(header.title)}></div>;
									})}
								</div>
								<div className='pd-plan-datatable-col pd-datatable-value'>
									{Object.entries(plan.columns).map(([key, value], index) => {
										return (
											<div key={key} className='col-6 col-lg mb-3 my-lg-0'>
												<div className='pd-plan-table-value' dangerouslySetInnerHTML={getSanitizedHtml(value, '&nbsp;')}></div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</Collapse>
			)}

			{/* <!-- Button - more info --> */}

			<div className='pd-plan-col pd-plan-button col-12 col-lg-1 mt-2 mb-5 my-lg-0'>
				<div className='row justify-content-center'>
					<div className='col-11 col-sm-8'>
						<div className='d-flex flex-column'>
							<button
								className='btn btn-outline-primary btn-md btn-icon d-lg-none'
								onClick={() => setMoreInfoMenuIsVisible(!moreInfoMenuIsVisible)}
								data-bs-toggle='collapse'
								data-bs-target={`#datatable-row-${index}`}
								aria-expanded='false'
								aria-controls={`datatable-row-${index}`}>
								{getConfigValue(appState.config?.PlanList?.Options?.MoreInfo.Label, appState)?.Value}{' '}
								<span className='fa-regular fa-chevron-down btn-icon'></span>
							</button>
						</div>
						{exceedMaxColumns && (
							<button
								className='btn btn-ghost-secondary btn-sm btn-icon-only d-none d-lg-flex'
								onClick={() => setMoreInfoMenuIsVisible(!moreInfoMenuIsVisible)}
								data-bs-toggle='collapse'
								data-bs-target={`#datatable-row-${index}`}
								aria-expanded='false'
								aria-controls={`datatable-row-${index}`}>
								<span className='fa-regular fa-chevron-down btn-icon'></span>
							</button>
						)}
					</div>
				</div>
			</div>
			<RestrictionModal show={showRestrictionModal} setShow={setShowRestrictionModal} plan={plan} />
			<FormularyModal show={showFormularyModal} setShow={setShowFormularyModal} plan={plan} />
			<SpecialtyPharmacyModal show={showSpecialtyPharmacy} setShow={setShowSpecialtyPharmacy} plan={plan} />
			<PrintRestrictionModal show={showPrintRestrictionModal} setShow={setShowPrintRestrictionModal} />
		</div>
	);
}

export default PlanListRow;
