import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useComponentVisible from '../hooks/useComponentVisible';
import getName from "../util/getName";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import { trackDocumentRequest, trackingEventType } from "../API/documentGenerate";

function GenerateDocumentPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [buttonRef, isDropdownVisible, setIsDropdownVisible] = useComponentVisible(false);
    const canDownload = getConfigValue(appState.config?.Generate?.Document?.Download?.Enabled, appState)?.Value === 'true' || false;
    const canPrint = getConfigValue(appState.config?.Generate?.Document?.Print?.Enabled, appState)?.Value === 'true' || false;
    const professionalPrintingEnabled = getConfigValue(appState.config?.ProfessionalPrinting?.Enabled, appState)?.Value === 'true' || false;
    const batchPrintEnabled = getConfigValue(appState.config?.BatchPrint?.Enabled, appState)?.Value === 'true' || false;

    const navigate = useNavigate();
    const { state } = useLocation();

    const [name, setName] = useState(getName(appState));
    const [territory, setTerritory] = useState(appState.territory?.name || "");
    const focusType = (appState?.focus);


    function handleClick() {
        setIsDropdownVisible(state => !state);
    }

    function downloadPDF() {
        const link = document.createElement('a');
        link.href = state?.pdfURL;
        link.download = state?.fileName;
        link.click();

        // track download request
        trackDocumentRequest(trackingEventType.DownLoad, appState.lastGeneratedDocument, appState.sellSheetId);
    }

    function printPDF(event) {
        event.preventDefault();
        window.open(state?.pdfURL, "PRINT");

        // track print request
        trackDocumentRequest(trackingEventType.Print, appState.lastGeneratedDocument, appState.sellSheetId);
    }

    function createProfessionalPrintingDocument() {
        navigate('/createProfessionalPrintingDocument', { state: { pdfURL: state?.pdfURL, fileName: state?.fileName } });
    }

    function startNewSalesAid(startNew = true) {
        setAppState({
            ...appState,
            ...(startNew && {
                market: {},
                territory: {}
            }),
            prescriber: {},
            products: [],
            currentProduct: "",
            plans: [],
            category: "",
            mainMessage: "",
        });
        if (focusType === "HCPs") {
            if (batchPrintEnabled && startNew) {
                navigate('/FocusPage');
            } else {
                navigateNewSalesAid(startNew, '/TerritoryPage', '/HCPPage')
            }
        } else if (focusType === "Messages") {
            navigateNewSalesAid(startNew, '/FocusPage', '/MessageSelectionPage')
        }
    }

    function navigateNewSalesAid(startNew, newPath, regularPath) {
        if (startNew) {
            navigate(newPath);
        } else {
            navigate(regularPath);
        }
    }

    return (
        <>
            <div className="title-bar">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="type-24 mt-4 mb-4">{getConfigValue(appState.config?.Focus?.Header, appState)?.Value || null}</h1>
                            <ul className="wizard-list hide-noncurrent-mobile horizontal mb-5">
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Territory?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Target?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Configure?.Label, appState)?.Value || null}</span>
                                </li>
                                <li className="wizard-step state-complete">
                                    <span className="wizard-step-icon"></span>
                                    <span className="wizard-step-label">{getConfigValue(appState.config?.Focus?.BreadCrumb?.Generate?.Label, appState)?.Value || null}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xl mt-5">
                <div className="row justify-content-center mb-5">
                    <div className="col-12 col-sm-10 col-lg-8">
                        <div className="alert alert-success" role="alert">
                            <div className="alert-icon"></div>
                            <div className="alert-content">
                                <h4 className="h5 alert-heading">
                                    {getConfigValue(appState.config?.GenerateDocumentPage?.MessageTitle, appState)?.Value}
                                </h4>
                                <p>
                                    {getConfigValue(appState.config?.GenerateDocumentPage?.MessageDescription, appState)?.Value}
                                </p>
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-6 col-md-auto type-14">
                                <div className="color-grey-d1">{getConfigValue(appState.config?.GenerateDocumentPage?.SelectedProvider?.Label, appState)?.Value || null}</div>
                                {name}
                            </div>
                            <div className="col-6 col-md-auto ms-md-6 type-14">
                                <div className="color-grey-d1">{getConfigValue(appState.config?.GenerateDocumentPage?.SelectedTerritory?.Label, appState)?.Value || null}</div>
                                {territory}
                            </div>
                        </div>
                        {(canPrint || canDownload || professionalPrintingEnabled) &&
                            <div className="card pd-card py-4 py-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-10 col-md-11">
                                        {canDownload &&
                                            <div className="row justify-content-md-center">
                                                <div className="col-12 col-md">
                                                    <h3 className="type-20">{getConfigValue(appState.config?.GenerateDocumentPage?.Download?.Label, appState)?.Value || null}</h3>
                                                    <p>
                                                        {getConfigValue(appState.config?.GenerateDocumentPage?.DownloadDescription, appState)?.Value}
                                                    </p>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-4 col-xxl-3">
                                                    <div className="d-flex flex-column">
                                                        <button className="btn btn-outline-primary btn-icon d-flex align-items-center mt-3"
                                                            onClick={downloadPDF}>{getConfigValue(appState.config?.GenerateDocumentPage?.Download?.Label, appState)?.Value || null} <span className="fa-regular fa-download btn-icon"></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {(canPrint && canDownload) &&
                                            <hr className="my-5" />
                                        }

                                        {canPrint &&
                                            <div className="row justify-content-md-center">
                                                <div className="col-12 col-md">
                                                    <h3 className="type-20">{getConfigValue(appState.config?.GenerateDocumentPage?.Print?.Label, appState)?.Value || null}</h3>
                                                    <p>
                                                        {getConfigValue(appState.config?.GenerateDocumentPage?.PrintDescription, appState)?.Value}
                                                    </p>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-4 col-xxl-3">
                                                    <div className="d-flex flex-column">
                                                        <button className="btn btn-outline-primary btn-icon d-flex align-items-center mt-3"
                                                            onClick={printPDF}>{getConfigValue(appState.config?.GenerateDocumentPage?.Print?.Label, appState)?.Value || null} <span className="fa-regular fa-print btn-icon"></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {((canPrint || canDownload) && professionalPrintingEnabled) &&
                                            <hr className="my-5" />
                                        }

                                        {professionalPrintingEnabled &&
                                            <div className="row justify-content-md-center">
                                                <div className="col-12 col-md">
                                                    <h3 className="type-20">{getConfigValue(appState.config?.ProfessionalPrinting?.SendToPrint, appState)?.Value || null}</h3>
                                                    <p>
                                                        {getConfigValue(appState.config?.ProfessionalPrinting?.SendToPrintDescription, appState)?.Value}
                                                    </p>
                                                </div>
                                                <div className="col-12 col-sm-6 col-md-4 col-xxl-3">
                                                    <div className="d-flex flex-column">
                                                        <button className="btn btn-outline-primary btn-icon d-flex align-items-center mt-3"
                                                            onClick={createProfessionalPrintingDocument}>{getConfigValue(appState.config?.ProfessionalPrinting?.SendToPrint, appState)?.Value || null} <span className="fa-regular fa-paper-plane btn-icon"></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="row justify-content-center mt-6 mb-7">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-8 col-xl-7 offset-xl-1 col-xxl-6 offset-xxl-2 justify-content-end">
                        <div className="d-flex flex-column flex-lg-row-reverse">
                            <div className="button-wrapper w-100">
                                <div className="dropdown dropup d-flex">
                                    <button
                                        onClick={handleClick}
                                        ref={buttonRef}
                                        className="btn btn-primary dropdown-toggle btn-lg pe-7 mb-3 mb-lg-0 ms-lg-3 pe-lg-3 flex-fill"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton, appState)?.Value}
                                    </button>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        style={{
                                            display: isDropdownVisible ? "block" : "none",
                                            position: "absolute",
                                            bottom: "48px",
                                        }}
                                    >
                                        <li className="mb-2">
                                            <span className="dropdown-item-text type-bold">
                                                {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton?.Question, appState)?.Value}
                                            </span>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <button type="button" to="#" className="dropdown-item" onClick={() => startNewSalesAid(false)}>
                                                {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton?.KeepTerritory, appState)?.Value}
                                            </button>
                                        </li>
                                        <li>
                                            <button type="button" to="#" className="dropdown-item" onClick={() => startNewSalesAid()}>
                                                {getConfigValue(appState.config?.GenerateDocumentPage?.NewButton?.BrandNew, appState)?.Value}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="button-wrapper flex-fill w-100">
                                <Link to="/">
                                    <button className="btn btn-secondary btn-lg pe-7 pe-lg-3 flex-fill w-100">
                                        {getConfigValue(appState.config?.GenerateDocumentPage?.DashboardButton, appState)?.Value}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default GenerateDocumentPage;