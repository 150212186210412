import React, { useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from '../../util/getConfigValue';
import { AppContext } from '../../util/AppContext';
import PropType from 'prop-types';
import BatchPrintList from '../BatchPrintList';


TargetModal.propTypes = {
    show: PropType.bool,
    setShow: PropType.func,
    selectedBatch: PropType.array,
    headers: PropType.array,
    handleAddClick: PropType.func,
    handleRemoveClick: PropType.func,
    onOptionsClick: PropType.func
}

function TargetModal({ show, setShow, selectedBatch, headers, handleAddClick, handleRemoveClick, onOptionsClick }) {
    const [appState] = useContext(AppContext);

    return (
        <Modal show={show} className="modal-xl" onHide={() => setShow(false)} scrollable={true}>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="modal-title type-20 fs-5">{getConfigValue(appState.config?.BatchPrint?.List?.Modal?.Title, appState)?.Value}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className='container-xl'>
                    <BatchPrintList
                        headers={headers}
                        batchRows={selectedBatch}
                        selectedBatch={selectedBatch}
                        handleAddClick={handleAddClick}
                        handleRemoveClick={handleRemoveClick}
                        onOptionsClick={onOptionsClick}
                        showBackButton={true}>
                    </BatchPrintList>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default TargetModal;