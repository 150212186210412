import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { getConfigValue } from '../../util/getConfigValue';
import { AppContext } from '../../util/AppContext';
import { computeBorderColorClass, getIndicatorClass } from '../../util/getBorderColor';
import { getSanitizedHtml } from '../../util/getSanitizedHtml';

import PropType from 'prop-types';



PlanListModal.propTypes = {
    show: PropType.bool,
    setShow: PropType.func,
    prescriberData: PropType.object,
    displayName: PropType.string,
    showBackButton: PropType.bool,
}

function PlanListModal({ show, setShow, prescriberData, displayName, showBackButton }) {
    const [appState] = useContext(AppContext);

    const prescriberPlans = prescriberData?.dynamicTable;

    return (
        <Modal show={show} className="modal-xl" onHide={() => setShow(false)} scrollable={true}>
            <Modal.Header className="primary" closeButton>
                <Modal.Title className="modal-title type-20 fs-5">{getConfigValue(appState.config?.BatchPrint?.Page?.SelectedPlans?.Title, appState)?.Value}</Modal.Title>
            </Modal.Header>
            {showBackButton ? <div
                className='d-flex justify-content-between align-items-center mb-2 '
                style={{
                    backgroundColor: "#DEE5EB",
                }}>
                <button type="button"
                    className=' btn btn-ghost btn-lg d-flex align-items-center mt-3 '
                    onClick={() => setShow(false)}>
                    <span
                        className="fa-regular fa-chevron-left btn-icon"
                        style={{ marginRight: "10px" }}>
                    </span>
                    {getConfigValue(appState.config?.BatchPrint?.Page?.BundleList?.Label, appState)?.Value}
                </button>
            </div> : ""}
            <Modal.Body className="modal-body">
                <h2 className="type-24">{getConfigValue(appState.config?.BatchPrint?.Page?.SelectedPlans?.Header, appState)?.Value} {displayName}</h2>
                <div className="alert alert-primary mt-3" role="alert">
                    <div className="alert-icon"></div>
                    <div className="alert-content">
                        <p className="m-0">{getConfigValue(appState.config?.BatchPrint?.Page?.Modal?.Alert, appState)?.Value}</p>
                    </div>
                </div>
                <div className='container-xl'>
                    <div className='row pd-plan-table-header align-items-lg-center d-none d-lg-flex py-3'>
                        <div className='pd-plan-col col-lg-12'>
                            <div className='row align-items-lg-center'>
                                {prescriberPlans?.headers.map((header) => (
                                    <div className='col-lg' key={header.key}>
                                        <div dangerouslySetInnerHTML={getSanitizedHtml(header.title)}></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {prescriberPlans?.rows.map((row) => {
                        return (
                            <div key={row.data.planId}className={`row pd-plan-table-row ${getIndicatorClass(computeBorderColorClass(row.data.isWin, row.data.isPrintable, row.data.planClass))} pd-plan-table-card align-items-lg-center py-3 py-lg-4 py-xl-5 `}>
                                <div className='pd-plan-col pd-plan-variable col-12 col-lg-12'>
                                    <div className='row align-items-lg-center'>
                                        {Object.entries(row.columns).map(([key, value], index) => {
                                            return (
                                                <div key={key} className='col-6 col-lg mb-3 my-lg-0'>
                                                    <div className='pd-plan-table-value' dangerouslySetInnerHTML={getSanitizedHtml(value)}></div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
        </Modal>

    );
}

export default PlanListModal;