import { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import QuickSheetRow from "../components/quicksheets-admin/QuickSheetRow";
import useComponentVisible from "../hooks/useComponentVisible";
import { getAllQuickSheets } from "../API/quickSheets";
import prettyDate from "../util/prettyDate";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from '../util/getConfigValue';
import QuickSheetsFilterDropdown from "../components/quicksheets-admin/QuickSheetsFilterDropdown";
import { filterByValue } from "../util/filterByValue";
import { useErrorBoundary } from "react-error-boundary";

function QuickSheetsAdminPage() {
    const [appState, setAppState] = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [selectedProducts, setSelectedProducts] = useState(appState?.productDetails);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [data, setData] = useState([]);
    const { showBoundary } = useErrorBoundary();
    const [filterMenuRef, filterMenuIsVisible, setFilterMenuIsVisible] = useComponentVisible(false);
    const [systemDateFormat] = useState(getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null);

    const defaultFilterState = {
        ...selectedProducts.reduce((acc, product) => {
            acc[product.name] = true; // setting all products to true by default
            return acc;
        }, {}),
        current: true,
        expired: false,
        hcpBased: true,
        noHcpAssociation: true,
    };
    const [filterState, setFilterState] = useState(defaultFilterState);

    function isExpired(expirationDate) {
        const today = new Date();
        const expiration = new Date(expirationDate);
        return expiration < today;
    }

    const getProductName = useCallback((productId) => {
        const products = appState?.productDetails;
        if (products.length > 0) {
            let productName = null || "";
            products.forEach(name => {
                if (name.clientProductId === productId) {
                    productName = name.name;
                }
            })
            return productName;
        } else {
            return null;
        }
    }, [appState.productDetails])

    // Call for quick sheets
    useEffect(() => {
        setIsLoading(true);
        getAllQuickSheets()
            .then((data) => {
                const collection = data.map(quickSheetData => ({
                    ...quickSheetData,
                    fromDate: prettyDate(quickSheetData.fromDate, systemDateFormat),
                    productName: getProductName(quickSheetData.productId),
                    expirationDate: prettyDate(quickSheetData.expirationDate, systemDateFormat),
                    planName: quickSheetData.plans.map(plan => plan.name).join(", "),
                    territory: quickSheetData.territories.map(territory => territory.name).join(" "),
                    isExpanded: false
                }))
                setData(collection)
            })
            .catch((error) => {
                showBoundary(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [getProductName])

    // Filter the quick sheets
    useEffect(() => {
        const filteredBySearch = filterByValue(data, searchInput, ['documentId', 'documentName', 'expirationDate', 'fromDate', 'productName', 'planName', 'territory'], false);
        const filteredByFilterState = filteredBySearch.filter(quickSheet => {
            const { current, expired, hcpBased, noHcpAssociation, ...selectedProducts } = filterState;
            let result = selectedProducts[quickSheet.productName];
            // filter is in an invalid state
            if ((!current && !expired) || (!hcpBased && !noHcpAssociation)) {
                return false;
            }

            if (result && !(current && expired)) {
                result = (current && !isExpired(quickSheet.expirationDate))
                    || (expired && isExpired(quickSheet.expirationDate));
            }

            if (result && !(hcpBased && noHcpAssociation)) {
                result = (hcpBased && (quickSheet.plans !== null && quickSheet.plans.length > 0))
                    || (noHcpAssociation && (quickSheet.plans === null || quickSheet.plans.length === 0));
            }

            return result;
        });
        setFilteredDocuments(filteredByFilterState);
    }, [data, searchInput, filterState])

    function refreshQuickSheets() {
        let updatedDocuments = [...filteredDocuments];
        setFilteredDocuments(updatedDocuments);
    }

    function setExpanded(value) {
        filteredDocuments.forEach((d) => d.isExpanded = value);

        refreshQuickSheets();
    }

    return (
        <>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/"><span className="fas fa-house"></span><span className="sr-only">{getConfigValue(appState?.config?.Common.Home, appState)?.Value || null}</span></Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Title, appState)?.Value || null}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="type-24 mb-4">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Title, appState)?.Value || null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-7">
                        <div className="input-group">
                            <input onChange={(e) => setSearchInput(e.target.value)} value={searchInput} type="text" className="form-control icon-search" id="users-search" aria-label="Search for documents" placeholder="Search for documents" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 col-md d-flex mt-3 mt-md-0 ps-md-0 justify-content-start justify-content-lg-end">
                        <div className="d-flex w-100">
                            <button
                                onClick={() => setFilterMenuIsVisible(!filterMenuIsVisible)}
                                className={`btn btn-outline-primary btn-icon w-100 me-2 ${filterMenuIsVisible ? "show" : ""}`}
                                data-bs-toggle="dropdown"
                                aria-expanded={filterMenuIsVisible}
                                data-bs-auto-close="outside">
                                {getConfigValue(appState?.config?.QuickSheets?.Admin?.Filter.Title, appState)?.Value || null}
                                <span className="fa-regular fa-chevron-down btn-icon"></span>
                            </button>
                            {filterMenuIsVisible &&
                                <QuickSheetsFilterDropdown
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    selectedProducts={selectedProducts}
                                    defaultFilterState={defaultFilterState}
                                />
                            }
                            <Link to="/QuickSheetsAdminPage-edit" state={{ isAddMode: true }} className="btn btn-outline-primary btn-icon w-100 ms-2">
                                {getConfigValue(appState?.config?.QuickSheets?.Admin?.Add?.Title, appState)?.Value || null}
                                <span className="fa-regular fa-plus btn-icon ms-2"></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl">
                <div className="row my-5 my-lg-4 align-items-center justify-content-lg-between ">
                    <div className="col-12 col-sm-9 col-md d-flex mt-3 mt-md-0 justify-content-start justify-content-lg-end ">
                        <div className="d-flex flex-row">
                            <button onClick={() => setExpanded(true)} className="btn btn-ghost-primary btn-icon btn-sm type-14 me-3">Expand all <span className="fa-regular fa-circle-plus btn-icon"></span></button>
                            <button onClick={() => setExpanded(false)} className="btn btn-ghost-primary btn-icon btn-sm type-14">Collapse all <span className="fa-regular fa-circle-minus btn-icon"></span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl mt-5 mb-5 mb-lg-7">
                <div className="container-xl">
                    {/* <!-- Plan table header row --> */}
                    <div className="row pd-plan-table-header align-items-lg-center d-flex py-3">
                        <div className="pd-plan-col col-10 col-md-6 col-lg-4">{getConfigValue(appState?.config?.QuickSheets?.Admin?.DocumentName, appState)?.Value || null}</div>
                        <div className="pd-plan-col col-sm-3 d-none d-lg-block">{getConfigValue(appState?.config?.QuickSheets?.Admin?.Product, appState)?.Value || null}</div>
                        <div className="pd-plan-col col-sm-3 d-none d-md-block">{getConfigValue(appState?.config?.QuickSheets?.Admin?.ValidityDates?.ExpirationDate, appState)?.Value || null}</div>
                        {/* <!-- Button - options --> */}
                        <div className="pd-plan-col pd-plan-button col-md-3 col-lg-2"></div>
                    </div>
                    {/* <!-- Content row --> */}

                    {isLoading ? (
                        // Loading
                        <div className="row justify-content-center mt-7">
                            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                <div className="d-flex justify-content-center">
                                    <div className="fa-spinner fa-spin-pulse fa-regular fa-3x text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : filteredDocuments.length > 0 ?
                        filteredDocuments?.map((quickSheet, index) => {
                            return (
                                <QuickSheetRow key={index} quickSheet={quickSheet} onExpanded={refreshQuickSheets} />
                            )
                        })
                        :
                        // No results found
                        <div className="row justify-content-center mt-5">
                            <div className="col-12 p-0">
                                <div className="alert alert-info gap-2" role="alert" style={{ flexDirection: 'column' }}>
                                    <h4 className="alert-heading">{getConfigValue(appState.config?.Common?.NoResultsFound, appState)?.Value}</h4>
                                    <p>{getConfigValue(appState.config?.Common?.AdjustFilters, appState)?.Value}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default QuickSheetsAdminPage;