import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from "../util/AppContext";
import { getSystemDates } from "../API/systemdata";
import prettyDate from "../util/prettyDate";
import { getConfigValue } from "../util/getConfigValue";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function Footer() {
	const [user, setUser] = useState('');
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [appState, setAppState] = useContext(AppContext);

	useEffect(() => {
		if (appState.configLoaded && appState.user && typeof appState.user === 'string' && !appState.releaseDate) {
			const dateFormat = getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null;
			setUser(appState.user);
			loadSystemDates(dateFormat);
		}
	}, [appState.user, appState.configLoaded, appState.configRefreshed]);

	async function loadSystemDates(dateFormat) {
		const data = await getSystemDates();
		setAppState({
			...appState,
			releaseDate: prettyDate(data.find((e) => e.type === 'Release_Date').date, dateFormat),
			formularyDate: prettyDate(data.find((e) => e.type === 'Formulary_Date').date, dateFormat),
			transactionDate: prettyDate(data.find((e) => e.type === 'Transaction_Date').date, dateFormat),
		});
	}

	function handleClick() {
		setShow(true);
	}

	return (
		<div className="pt-7">
			<footer className="footer">
				<div className="footer-content">
					<div className="container-xl">
						<div className="row align-items-center py-4 py-md-0">
							<div className="col-12 col-md-6">
								<p className="m-0 text-center text-md-start type-12">{appState.impersonate.isActive ? appState.impersonate.email : user || "Email not found."} <span className="mx-2">|</span> {getConfigValue(appState?.config?.SystemDates?.ReleaseDate, appState)?.Value || null}: {appState?.releaseDate || null}  <span className="mx-2">|</span>  {getConfigValue(appState?.config?.SystemDates?.FormularyDate, appState)?.Value || null}: {appState?.formularyDate || null}</p>
							</div>
							<div className="col-12 col-md-6 mt-5 mt-md-0">
								<div className="row align-items-center justify-content-end">
									<div className="col-12 col-md-auto order-md-2">
										<div className="footer-logo-product-wrapper pt-md-1 pb-md-1 ps-md-5">
											<img src={getConfigValue(appState.config?.ClientBranding?.AccessGeniusLogo, appState)?.Value}
												alt="Powered by Access Genius"
												width={"auto"}
												height={"55px"}
												className="mx-auto d-block"
											></img>
										</div>
									</div>
									<div className="col-12 col-md order-md-1 d-flex justify-content-center justify-content-md-end">
										<Button variant="btn btn-link" className="btn-sm type-14 pe-0 mt-3 mt-md-0" onClick={handleClick}>
											{getConfigValue(appState?.config?.Site?.SystemInfo?.Label, appState)?.Value || null}<span className="fa-regular fa-calendar-range btn-icon ms-2"></span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton >
					<Modal.Title>{getConfigValue(appState?.config?.SystemDates?.Title, appState)?.Value || null}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ul className="list-group list-group-flush">
						<li className="list-group-item py-3">
							<h2 className="type-16 type-bold">{getConfigValue(appState?.config?.SystemDates?.ReleaseDate, appState)?.Value || null}</h2>
							<p>{getConfigValue(appState?.config?.SystemDates?.ReleaseDate?.Message, appState)?.Value || null}</p>
							<p className="color-grey-d1 my-0">{appState?.releaseDate || null}</p>
						</li>
						<li className="list-group-item py-3">
							<h2 className="type-16 type-bold my-0">{getConfigValue(appState?.config?.SystemDates?.FormularyDate, appState)?.Value || null}</h2>
							<p>{getConfigValue(appState?.config?.SystemDates?.FormularyDate?.Message, appState)?.Value || null}</p>
							<p className="color-grey-d1 my-0">{appState?.formularyDate || null}</p>
						</li>
						<li className="list-group-item py-3">
							<h2 className="type-16 type-bold">{getConfigValue(appState?.config?.SystemDates?.TransactionDate, appState)?.Value || null}</h2>
							<p>{getConfigValue(appState?.config?.SystemDates?.TransactionDate?.Message, appState)?.Value || null}</p>
							<p className="color-grey-d1 my-0">{appState?.transactionDate || null}</p>
						</li>
						<li className="list-group-item py-3">
							<h2 className="type-16 type-bold">{getConfigValue(appState?.config?.SystemDates?.Version, appState)?.Value || null}</h2>
							<p>{getConfigValue(appState?.config?.SystemDates?.Version?.Message, appState)?.Value || null}</p>
							<p className="color-grey-d1 my-0">{process.env.REACT_APP_VERSION}</p>
						</li>
					</ul>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default Footer