import '../src/styles/assets/ag-css/ag.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Layout from '../src/components/layout';
import Dashboard from '../src/pages/dashBoard';
import FocusPage from '../src/pages/focusPage';
import TerritoryPage from '../src/pages/territoryPage';
import HCPPage from '../src/pages/hcpPage';
import MessageSelectionPage from './pages/messageSelectionPage';
import PlanListPage from './pages/planListPage';
import ErrorPage from '../src/pages/errorPage';
import { AppContextProvider } from "../src/util/AppContext";
import AuthRoutes from './util/AuthRoutes';
import WinPlanListPage from './pages/winPlanListPage';
import UserInfoPage from './userManagement/userInfoPage';
import AddUserPage from './userManagement/addUserPage';
import EditUserPage from './userManagement/editUserPage';
import AddressPage from './userManagement/addressManagement/AddressPage';
import AddressPageEdit from './userManagement/addressManagement/AddressPageEdit';
import GenerateDocumentPage from './pages/generateDocumentPage';
import QuickSheetsAdminPage from './pages/quickSheetsAdminPage';
import QuickSheetsAdminEdit from './pages/quickSheetsAdminPageEdit';
import QuickSheetsPage from './pages/quickSheetsPage';
import { HelpContactPage } from './pages/helpContactPage';
import ReactErrorBoundary from './util/ErrorBoundary';
import CreateProfessionalPrintingDocument from './pages/createProfessionalPrintingDocument';
import SuccessfullySubmitPrintingRequest from './pages/successfullySubmitPrintingRequest';
import AlignedMessagesForUserPage from './pages/alignedMessagesForUserPage';
import HealthPage from './pages/healthPage';
import SelectTargetBatchPage from './pages/selectTargetBatchPage';

export default function App() {

    return (
        <AppContextProvider>
            <ReactErrorBoundary>
                <BrowserRouter>
                    <Routes>
                        <Route element={<AuthRoutes />}>
                            <Route path="/" element={<Layout />} >
                                <Route index element={<Dashboard />} />
                                <Route path="FocusPage" element={<FocusPage />} />
                                <Route path="TerritoryPage" element={<TerritoryPage />} />
                                <Route path="HCPPage" element={<HCPPage />} />
                                <Route path="MessageSelectionPage" element={<MessageSelectionPage />} />
                                <Route path="SelectTargetBatchPage" element={<SelectTargetBatchPage />} />
                                <Route path="PlanListPage" element={<PlanListPage />} />
                                <Route path="WinPlanListPage" element={<WinPlanListPage />} />
                                <Route path="UserInfoPage" element={<UserInfoPage />} />
                                <Route path="AddUserPage" element={<AddUserPage />} />
                                <Route path="EditUserPage" element={<EditUserPage />} />
                                <Route path="AddressPage" element={<AddressPage />} />
                                <Route path="AddressPage-edit" element={<AddressPageEdit />} />
                                <Route path="GenerateDocumentPage" element={<GenerateDocumentPage />} />
                                <Route path="QuickSheetsAdminPage" element={<QuickSheetsAdminPage />} />
                                <Route path="QuickSheetsAdminPage-edit" element={<QuickSheetsAdminEdit />} />
                                <Route path="QuickSheetsPage" element={<QuickSheetsPage />} />
                                <Route path="HelpAndContact" element={<HelpContactPage />} />
                                <Route path="CreateProfessionalPrintingDocument" element={<CreateProfessionalPrintingDocument />} />
                                <Route path="SuccessfullySubmitPrintingRequest" element={<SuccessfullySubmitPrintingRequest />} />
                                <Route path="alternate" element={<Dashboard />} />
                                <Route path="MessagePlans/:commonTerritoryId/:prescriberId/:level/:productId/:messageCode" element={<AlignedMessagesForUserPage />} />
                            </Route>
                        </Route>
                        <Route path="/health" element={<HealthPage />} />
                        <Route path="/ErrorPage" element={<ErrorPage />} />
                    </Routes>
                </BrowserRouter>
            </ReactErrorBoundary>
        </AppContextProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />

);
