import { useState, useEffect, useContext } from 'react';
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell } from '@table-library/react-table-library/table';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';
import { AppContext } from '../util/AppContext';
import { getConfigValue } from '../util/getConfigValue';
import PropType from 'prop-types';
import { Pagination } from '../components/Pagination';
import { getSanitizedHtml } from "../util/getSanitizedHtml";
import { getBorderColorClass } from '../util/getBorderColor'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import PlanListModal from "../components/modals/PlanListModal";
import { getBatchPrintPrescriber } from '../API/batchPrint';



BatchPrintList.propTypes = {
    headers: PropType.array,
    batchRows: PropType.array,
    selectedBatch: PropType.array,
    handleAddClick: PropType.func,
    handleRemoveClick: PropType.func,
    onOptionsClick: PropType.func,
    showBackButton: PropType.bool
}

function BatchPrintList({ headers, batchRows, handleAddClick, handleRemoveClick, selectedBatch, onOptionsClick, showBackButton}) {
    const [appState] = useContext(AppContext);
    const [data, setData] = useState({ nodes: [] });
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [showPlanListModal, setShowPlanListModal] = useState(false);
    const [prescriberData, setPrescriberData] = useState();
    const [displayName, setDisplayName] = useState('');
    


    // Pagination
    const pagination = usePagination(
        data,
        {
            state: {
                page: 0,
                size: appState.config?.BatchPrint?.Page?.MaxNumber?.Display?.Value
            },
        },
    );

    /* THEME */
    const columnsCount = headers ? headers?.length : 2;
    const themeObject = {
        Table: `
            --data-table-library_grid-template-columns: repeat(${columnsCount}, minmax(0, 1fr)) 10%;
        `,
        Row: `
        &.target-table-indicator-success .td:first-of-type{
            border-left: 2px solid green;
        }
      `,
        BaseCell: `
      & > div {
        overflow: visible;
      }
    `
    };

    const themeColumns = useTheme(themeObject);

    //build sort functions object
    const sortFunctions = {};
    for (const header of headers) {
        sortFunctions[header.key] = (array) => array.sort((a, b) => a.columns[header.key]?.localeCompare(b.columns[header.key]));
    }

    let defaultSortKey = "displayName";
    if (headers && headers.length > 0) {
        defaultSortKey = headers[0].key;
    }

    /* SORT */
    const sort = useSort(
        data,
        {
            state:
            {
                sortKey: defaultSortKey,
                reverse: false,
            }
        },
        {
            sortFns: sortFunctions
        }
    );

    //TODO move out inline style into its own class
    const setActiveIconColor = {
        color: "white"
    }
    const getIcon = (sortKey) => {
        if (sort.state.sortKey === sortKey && sort.state.reverse) {
            return <span className='fa-solid fa-arrow-up-short-wide' style={setActiveIconColor}></span>;
        }

        if (sort.state.sortKey === sortKey && !sort.state.reverse) {
            return <span className="fa-solid fa-arrow-down-short-wide" style={setActiveIconColor}></span>;
        }

        return <span className='fa-solid fa-sort' />;
    };

    useEffect(() => {
        if (batchRows) {
            setData({ nodes: batchRows })
            const pageSize = parseInt(getConfigValue(appState.config?.BatchPrint?.Page?.MaxNumber?.Display, appState)?.Value, 10);
            let pages = Math.floor(batchRows.length / pageSize);
            pages = batchRows.length % pageSize > 0 ? pages + 1 : pages;
            setNumberOfPages(pages);
            pagination.fns.onSetPage(0);
        }
    }, [batchRows, selectedBatch])

    function handleIncludeTargetNameClick(item, event) {
        const newItem = { ...item, includeTargetName: event.target.checked }
        if (!onOptionsClick(newItem)) {
            event.preventDefault();
        }
    }

    function isTargetChecked(item) {
        const result = selectedBatch.find((d) => d.data.id === item.data.id);
        return result ? result.includeTargetName : false;
    }

    async function getPrescriberData(item) {
        const prescriberData = await getBatchPrintPrescriber(item.type, appState.messageCode, item.id, appState?.currentProduct?.clientProductId, appState?.category?.messageTypeId, appState?.territory?.id);
        setDisplayName(item.displayName);
        setPrescriberData(prescriberData);
        setShowPlanListModal(true);
    }

    return (
        <div className="container-xl mt-5 mb-5 mb-lg-7">
            <div className="row justify-content-center">
                <div className="col-12">
                    {headers &&
                        <Table className='table pd-table pod-table-hcp pod-table-batchprint' data={data} sort={sort} theme={themeColumns} layout={{ custom: true, isDiv: true }} pagination={pagination}>
                            {(tableList) => (
                                <>
                                    <Header>
                                        <HeaderRow className='table-dark d-none d-md-contents'>
                                            {headers.map((header) =>
                                                <HeaderCell className='px-2 py-2' key={header.key} scope='col'>
                                                    {header.title}<button
                                                        className='btn-ghost btn-table'
                                                        children={getIcon(header.key)}
                                                        onClick={() =>
                                                            sort.fns.onToggleSort({
                                                                sortKey: header.key,
                                                            })
                                                        }
                                                    ></button>
                                                </HeaderCell>)}
                                            <HeaderCell scope='col'></HeaderCell>
                                        </HeaderRow>
                                    </Header>

                                    <Body>
                                        {tableList.map((item) => (
                                            <Row key={item.data.id}
                                                item={item}
                                                className={`row pd-plan-table-row ${getBorderColorClass(item.data.hasWinPlans)}`}>
                                                {Object.keys(item.columns).map((key) => <Cell className='px-2 py-2' key={key}>
                                                    {<span className="d-block d-md-none color-grey-d1">{item.columns[key] ? headers.find(h => h.key === key)?.title : ""}</span>}
                                                    <span className="form-check-label" dangerouslySetInnerHTML={getSanitizedHtml(item.columns[key])}></span></Cell>)}
                                                <Cell>
                                                    <div className="pd-plan-table-card justify-content-end" style={{ boxShadow: 'none' }}>
                                                        <Dropdown autoClose="outside" drop="start">
                                                            <Dropdown.Toggle className='btn btn-ghost-secondary btn-sm btn-icon-only'>
                                                                <span className='fa-regular fa-ellipsis btn-icon'></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Header className="type-bold type-16 mt-0 mb-2">{getConfigValue(appState.config?.BatchPrint?.Page?.Provider?.Header, appState)?.Value}</Dropdown.Header>
                                                                <DropdownItem>
                                                                    <div className="form-check mb-3 mb-md-0">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id={`provider-name-${item.data.id}`}
                                                                            onChange={(event) => handleIncludeTargetNameClick(item, event)}
                                                                            checked={isTargetChecked(item)}>
                                                                        </input>
                                                                        <label className="form-check-label type-14" htmlFor={`provider-name-${item.data.id}`}>{getConfigValue(appState.config?.BatchPrint?.Page?.IncludeTarget?.Label, appState)?.Value}</label>
                                                                    </div>
                                                                </DropdownItem>
                                                                <Dropdown.Header className="type-bold type-16 mt-0 mb-2">{getConfigValue(appState.config?.BatchPrint?.Page?.Provider?.Header, appState)?.Value}</Dropdown.Header>
                                                                <DropdownItem
                                                                    id={`provider-name-${item.data.id}`}
                                                                    className='dropdown-item cursor type-14 btn btn-ghost-primary btn-sm'
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#selected_target_plans"
                                                                    onClick={() => getPrescriberData(item.data)}
                                                                >
                                                                    {getConfigValue(appState.config?.BatchPrint?.Page?.ViewPlans?.Label, appState)?.Value}
                                                                </DropdownItem>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        {!selectedBatch.find((d) => d.data === item.data) ?
                                                            <button type="button" className="btn btn-outline-primary btn-icon-only ms-3"
                                                                onClick={(event) => handleAddClick(item, event)}>
                                                                <span className="fa-regular fa-plus btn-icon"></span>
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-outline-danger btn-icon-only ms-3"
                                                                onClick={(event) => handleRemoveClick(item, event)}>
                                                                <span className="fa-regular fa-minus btn-icon"></span>
                                                            </button>
                                                        }
                                                    </div>
                                                </Cell>
                                            </Row>
                                        ))}
                                    </Body>
                                </>
                            )}
                        </Table>
                    }
                    {numberOfPages > 1 && pagination && <Pagination numberOfPages={pagination.state.getTotalPages(data.nodes)} currentPage={pagination.state.page + 1} setCurrentPage={(index, e) => {
                        e.preventDefault();
                        pagination.fns.onSetPage(index - 1);
                    }} />}
                </div>
            </div>

            <PlanListModal
                show={showPlanListModal}
                setShow={setShowPlanListModal}
                prescriberData={prescriberData}
                displayName={displayName}
                showBackButton={showBackButton}
            />
        </div>
    )
}

export default BatchPrintList;
