export function computeBorderColorClass(isWin, isPrintable, planClass) {
    if (isWin) {
        if (!isPrintable) {
            return 'success-danger'; // green-red
        } else if (planClass !== null) {
            return 'success-warning'; // green-yellow
        } else {
            return 'success'; // green
        }
    } else if (!isPrintable) {
        return 'danger'; // red
    } else if (planClass !== null) {
        return 'warning'; // yellow
    }
}

export function getIndicatorClass(borderColorClass) {
    if (!borderColorClass) {
        return;
    }

    const classes = borderColorClass.split('-');

    let indicatorClass = `pd-plan-table-indicator-${classes[0]}`;

    if (classes.length > 1) {
        indicatorClass += ` pd-plan-table-secondary-indicator-${classes[1]}`;
    }

    return indicatorClass;
}

export function getBorderColorClass(hasWinPlans) {
    if (hasWinPlans) {
        return `target-table-indicator-success`;
    }

}