import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AlertModal from '../../components/AlertModal';
import AddressRow from '../../components/AddressRow';
import { getAddresses } from '../../API/address';
import { AppContext } from "../../util/AppContext";
import { getConfigValue } from "../../util/getConfigValue";

function AddressPage() {
    const [appState] = useContext(AppContext);
    const { state } = useLocation();

    const [user] = useState((appState.impersonate.isActive === true ? appState.impersonate.email : appState.user) || null);
    const [alert, setAlert] = useState(state?.alert || null);
    const [feedbackMessage, setFeedbackMessage] = useState();
    const [addresses, setAddresses] = useState([]);

    function loadAddresses() {
        getAddresses(user)
            .then(responseJson => {
                if (responseJson && Array.isArray(responseJson) && responseJson.length > 0) {
                    const sortedAddresses = sortByPrimary([...responseJson]);
                    setAddresses(sortedAddresses);
                    setFeedbackMessage(null);
                } else {
                    setFeedbackMessage(getConfigValue(appState?.config?.AddressManagement?.Messages?.NoAddresses, appState)?.Value);
                }
            })
            .catch(error => {
                setFeedbackMessage(getConfigValue(appState?.config?.AddressManagement?.Messages?.NoAddresses, appState)?.Value);
            });
    }

    useEffect(() => {
        if (user) {
            loadAddresses();
        }
    }, [user, appState.config])

    function sortByPrimary(addresses) {
        return addresses.sort((a, b) => b.isPrimary - a.isPrimary);
    };

    return (
        <>
            <nav aria-label="breadcrumb" className="breadcrumb-wrapper">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <span className="fas fa-house"></span>
                                        <span className="sr-only">{getConfigValue(appState?.config?.Common.Home, appState)?.Value || null}</span>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{getConfigValue(appState?.config?.AddressManagement?.Title, appState)?.Value || null}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10">
                        {alert && (
                            <AlertModal alert={alert} />
                        )}
                    </div>
                </div>
            </div>
            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10">
                        <div className="row d-flex align-items-center justify-content-center justify-content-md-between">
                            <div className="col-12 col-sm">
                                <h1 className="type-24 mb-4 mb-sm-0">{getConfigValue(appState?.config?.AddressManagement?.Title, appState)?.Value || null}</h1>
                            </div>
                            <div className="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3 d-flex mt-3 mt-sm-0">
                                <div className="d-flex w-100">
                                    <Link
                                        to="/AddressPage-edit"
                                        state={{ userId: user }}
                                        className="btn btn-outline-primary btn-icon flex-fill"
                                    >
                                        {getConfigValue(appState?.config?.AddressManagement?.NewAddress, appState)?.Value || null}
                                        <span className="fa-regular fa-plus btn-icon"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xl mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10">
                        {!feedbackMessage && addresses && addresses.length > 0 && (<div className="card pd-card py-3 mb-5">
                            <div className="container-xl">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <ul className="list-group list-group-flush">
                                            {
                                                addresses.map((address, index) => {
                                                    return (
                                                        <AddressRow key={index} address={address} userId={user} setAlert={setAlert} loadAddresses={loadAddresses} />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                        {feedbackMessage && (
                            <div>
                                <div className="col-12 p-0">
                                    <div className="alert alert-primary" role="alert">
                                        <div className="alert-icon"></div>
                                        <div className="alert-content">
                                            <p>{feedbackMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddressPage;